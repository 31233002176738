const ElementQueries = require( 'css-element-queries/src/ElementQueries' );

import $ from 'jquery';

import {
	isDefined,
	isEmpty
} from '../../common/js/utilities.js';

class CourseDashboard {
	constructor(){
		// Check if the required container is defined
		if ( this.hasDashboard() ){
			// Init ResizeSensor
			ElementQueries.init();

			// Get elements
			this.getElements();

			// Define global settings
			this.defineSettings();
			
			// Create courses using the DOM elements
			this.createCourses();

			// Add function to expand and collapse all containers
			this.slideAll();

			// Listen filters
			this.filters();
		}
	}

	getElements(){
		this.$elements = {
			filters: {
				form:    $( '#ultp-dashboard-filters-form' ),
				selects: $( '#ultp-dashboard-filters-form select' )
			}
		}
	}

	defineSettings(){
		// Create settings
		this.settings = {
			slideToggleDuration: 300
		}
	}

	createCourses(){
		// Get all courses
		const $courses = $( '.ultp-dashboard-course' );

		// Create Courses
		$.each( $courses, ( index, $element ) => {
			// Create instance of Course
			new Course( $( $element ), this.settings );
		});
	}

	hasDashboard(){
		return $( '.ultp-dashboard-courses' ).length > 0;
	}

	slideAll(){
		// Listen to the clicks on the buttons
		$( '.ultp-dashboard .ultp-dashboard-btn--expand-all' ).on( 'click', () => {
			expandAll();
		});

		$( '.ultp-dashboard .ultp-dashboard-btn--collapse-all' ).on( 'click', () => {
			collapseAll();
		});

		const expandAll = () => {
			// Get all the collapsed containers
			const collapsedContainers = {
				courses: {
					container: '.ultp-dashboard-course--collapsed',
					toggle:    '.ultp-dashboard-course__toggle-btn'
				},
				lessons: {
					container: '.ultp-dashboard-lesson--collapsed',
					toggle:    '.ultp-dashboard-lesson__toggle-btn'
				},
				quizzes: {
					container: '.ultp-dashboard-quizzes--collapsed',
					toggle:    '.ultp-dashboard-quizzes__header-toggle-btn'
				}
			}

			$.each( collapsedContainers, ( index, elementType ) => {
				// Get containers
				const $containers = $( elementType.container );

				// Iterate each container
				$.each( $containers, ( index, container ) => {
					// Get container element
					const $container = $( container );

					// Get toggle button
					const $toggleButton = $container.find( elementType.toggle );

					// Trigger click
					$toggleButton.trigger( 'click' );
				});
			});
		}

		const collapseAll = () => {
			// Get all the expanded containers
			const expandedContainers = {
				courses: {
					container: '.ultp-dashboard-course--expanded',
					toggle:    '.ultp-dashboard-course__toggle-btn'
				},
				lessons: {
					container: '.ultp-dashboard-lesson--expanded',
					toggle:    '.ultp-dashboard-lesson__toggle-btn'
				},
				quizzes: {
					container: '.ultp-dashboard-quizzes--expanded',
					toggle:    '.ultp-dashboard-quizzes__header-toggle-btn'
				}
			}

			$.each( expandedContainers, ( index, elementType ) => {
				// Get containers
				const $containers = $( elementType.container );

				// Iterate each container
				$.each( $containers, ( index, container ) => {
					// Get container element
					const $container = $( container );

					// Get toggle button
					const $toggleButton = $container.find( elementType.toggle );

					// Trigger click
					$toggleButton.trigger( 'click' );
				});
			});
		}
	}

	filters(){
		// Listen to changes
		this.$elements.filters.selects.on( 'change', () => {
			// Submit form
			this.$elements.filters.form.trigger( 'submit' );
		});
	}
}

class Course {
	constructor( $container, settings ){
		// Save settings in the object's instance
		this.dashboardSettings = settings;

		// Define the important CSS classes
		this.defineCssClasses();

		// Get important elements from the container
		this.getElements( $container );

		// Get course data
		this.getCourseData();

		// Add toggle functionality
		this.toggleCourseContent();

		// Create lessons using the DOM elements
		this.createLessons();

		// Create quizzes using the DOM elements
		this.createQuizzes();
	}

	getCourseData(){
		this.courseData = {
			courseId:       this.$elements.container.data( 'course-id' ),
			status:         this.$elements.container.data( 'status' ),
			hasLessons:     !! parseInt( this.$elements.container.data( 'has-lessons' ) ),
			hasQuizzes:     !! parseInt( this.$elements.container.data( 'has-quizzes' ) ),
			hasCertificate: !! parseInt( this.$elements.container.data( 'has-certificate' ) ),
		}
	}

	defineCssClasses(){
		this.cssClasses = {
			toggle: {
				collapsed:  'ultp-dashboard-course--collapsed',
				collapsing: 'ultp-dashboard-course--collapsing',
				expanding:  'ultp-dashboard-course--expanding',
				expanded:   'ultp-dashboard-course--expanded',
			}
		}
	}

	getElements( $container ){
		this.$elements = {
			container: $container,
			toggleBtn: $container.find( '.ultp-dashboard-course__toggle-btn' ),
			content:   $container.find( '.ultp-dashboard-course__content' )
		}
	}

	toggleCourseContent(){
		// Check if the course has lessons or quizzes
		if ( this.courseData.hasLessons || this.courseData.hasQuizzes ){
			// Listen to clicks on the toggle button
			this.$elements.toggleBtn.on( 'click', () => {
				// Check if we have to close it or open it
				if ( this.isCollapsed() ){
					// As it's collapsed, we have to expand it

					// Set status
					this.setToggleClass( 'expanding' );

					// Do the slideDown
					this.$elements.content.hide().slideDown( this.dashboardSettings.slideToggleDuration, () => {
						// Set status
						this.setToggleClass( 'expanded' );
					});

				}
				else {
					// As it's expanded, we have to collapse it

					// Set status
					this.setToggleClass( 'collapsing' );

					// Do the slideDown
					this.$elements.content.show().slideUp( this.dashboardSettings.slideToggleDuration, () => {
						// Set status
						this.setToggleClass( 'collapsed' );
					});
				}
			});
		}
	}

	createLessons(){
		// Get all lessons
		const $lessons = this.$elements.container.find( '.ultp-dashboard-lesson' );

		// Create Courses
		$.each( $lessons, ( index, $element ) => {
			// Create instance of Course
			new Lesson( $( $element ), this.dashboardSettings, this.courseData );
		});
	}

	createQuizzes(){
		// Get all quizzes containers
		const $quizzesContainers = this.$elements.container.find( '.ultp-dashboard-course__quizzes .ultp-dashboard-quizzes' );

		// Create Quizzes
		$.each( $quizzesContainers, ( index, $element ) => {
			// Create instance of Course
			new Quizzes( $( $element ), this.dashboardSettings, this.courseData );
		});
	}

	setToggleClass( status ){
		// First remove all the classes
		this.$elements.container.removeClass( Object.values( this.cssClasses.toggle ).join( ' ' ) );

		// Then add the correct class
		this.$elements.container.addClass( this.cssClasses.toggle[ status ] );
	}

	isCollapsed(){
		// Check if the container has the collapsed class
		return this.$elements.container.hasClass( this.cssClasses.toggle.collapsed );
	}
}

class Lesson {
	constructor( $container, settings, courseData ){
		// Save settings in the object's instance
		this.dashboardSettings = settings;
		// Save courseData
		this.courseData = courseData;

		// Define the important CSS classes
		this.defineCssClasses();

		// Get important elements from the container
		this.getElements( $container );

		// Get lesson data
		this.getLessonData();

		// Add toggle functionality
		this.toggleLessonContent();

		// Create topics using the DOM elements
		this.createTopics();

		// Create quizzes using the DOM elements
		this.createQuizzes();
	}

	getLessonData(){
		this.lessonData = {
			course:         this.courseData,
			lessonId:       this.$elements.container.data( 'lesson-id' ),
			availableOn:    ! isEmpty( this.$elements.container.data( 'available-on' ) ) ? parseInt( this.$elements.container.data( 'available-on' ) ) : null,
			isCompleted:    !! parseInt( this.$elements.container.data( 'is-completed' ) ),
			isAvailable:    !! parseInt( this.$elements.container.data( 'is-available' ) ),
			hasTopics:      !! parseInt( this.$elements.container.data( 'has-topics' ) ),
			hasQuizzes:     !! parseInt( this.$elements.container.data( 'has-quizzes' ) ),
		}
	}

	defineCssClasses(){
		this.cssClasses = {
			toggle: {
				collapsed:  'ultp-dashboard-lesson--collapsed',
				collapsing: 'ultp-dashboard-lesson--collapsing',
				expanding:  'ultp-dashboard-lesson--expanding',
				expanded:   'ultp-dashboard-lesson--expanded',
			}
		}
	}

	getElements( $container ){
		this.$elements = {
			container: $container,
			toggleBtn: $container.find( '.ultp-dashboard-lesson__toggle-btn' ),
			content:   $container.find( '.ultp-dashboard-lesson__content' )
		}
	}

	toggleLessonContent(){
		// Check if the lesson has topics or quizzes
		if ( this.lessonData.hasTopics || this.lessonData.hasQuizzes ){
			// Listen to clicks on the toggle button
			this.$elements.toggleBtn.on( 'click', () => {
				// Check if we have to close it or open it
				if ( this.isCollapsed() ){
					// As it's collapsed, we have to expand it

					// Set status
					this.setToggleClass( 'expanding' );

					// Do the slideDown
					this.$elements.content.hide().slideDown( this.dashboardSettings.slideToggleDuration, () => {
						// Set status
						this.setToggleClass( 'expanded' );
					});

				}
				else {
					// As it's expanded, we have to collapse it

					// Set status
					this.setToggleClass( 'collapsing' );

					// Do the slideDown
					this.$elements.content.show().slideUp( this.dashboardSettings.slideToggleDuration, () => {
						// Set status
						this.setToggleClass( 'collapsed' );
					});
				}
			});
		}
	}

	createTopics(){
		// Get all topics
		const $topics = this.$elements.container.find( '.ultp-dashboard-topic' );

		// Create Courses
		$.each( $topics, ( index, $element ) => {
			// Create instance of Course
			new Topic( $( $element ), this.dashboardSettings, this.courseData, this.lessonData );
		});
	}

	createQuizzes(){
		// Get all quizzes containers
		const $quizzesContainers = this.$elements.container.find( '.ultp-dashboard-lesson__quizzes .ultp-dashboard-quizzes' );

		// Create Quizzes
		$.each( $quizzesContainers, ( index, $element ) => {
			// Create instance of Course
			new Quizzes( $( $element ), this.dashboardSettings, this.courseData, this.lessonData );
		});
	}

	setToggleClass( status ){
		// First remove all the classes
		this.$elements.container.removeClass( Object.values( this.cssClasses.toggle ).join( ' ' ) );

		// Then add the correct class
		this.$elements.container.addClass( this.cssClasses.toggle[ status ] );
	}

	isCollapsed(){
		// Check if the container has the collapsed class
		return this.$elements.container.hasClass( this.cssClasses.toggle.collapsed );
	}
}

class Topic {
	constructor( $container, settings, courseData, lessonData ){
		// Save settings in the object's instance
		this.dashboardSettings = settings;
		// Save course data
		this.courseData = courseData;
		// Save lesson data
		this.lessonData = lessonData;

		// Get elements
		this.getElements( $container );

		// Get lesson data
		this.getTopicData();
	}

	getElements( $container ){
		this.$elements = {
			container: $container
		}
	}

	getTopicData(){
		this.topicData = {
			course:         this.courseData,
			lesson:         this.lessonData,
			topicId:        this.$elements.container.data( 'topic-id' ),
			isCompleted:    !! parseInt( this.$elements.container.data( 'is-completed' ) ),
		}

		delete this.topicData.lesson.course;
	}
}

class Quizzes {
	constructor( $container, settings, courseData, lessonData = false ){
		// Save settings in the object's instance
		this.dashboardSettings = settings;
		// Save course data
		this.courseData        = courseData;
		// Save lesson data
		this.lessonData        = lessonData;

		// Define the important CSS classes
		this.defineCssClasses();

		// Get important elements from the container
		this.getElements( $container );

		// Add toggle functionality
		this.toggleQuizzesContent();

		// Create Quiz instances
		this.createIndividualQuizzes();
	}

	defineCssClasses(){
		this.cssClasses = {
			individual:     'ultp-dashboard-quiz',
			toggle: {
				collapsed:  'ultp-dashboard-quizzes--collapsed',
				collapsing: 'ultp-dashboard-quizzes--collapsing',
				expanding:  'ultp-dashboard-quizzes--expanding',
				expanded:   'ultp-dashboard-quizzes--expanded',
			}
		}
	}

	getElements( $container ){
		this.$elements = {
			container: $container,
			toggleBtn: $container.find( '.ultp-dashboard-quizzes__header-toggle-btn' ),
			content:   $container.find( '.ultp-dashboard-quizzes__list' )
		}
	}

	toggleQuizzesContent(){
		// Listen to clicks on the toggle button
		this.$elements.toggleBtn.on( 'click', () => {
			// Check if we have to close it or open it
			if ( this.isCollapsed() ){
				// As it's collapsed, we have to expand it

				// Set status
				this.setToggleClass( 'expanding' );

				// Do the slideDown
				this.$elements.content.hide().slideDown( this.dashboardSettings.slideToggleDuration, () => {
					// Set status
					this.setToggleClass( 'expanded' );
				});

			}
			else {
				// As it's expanded, we have to collapse it

				// Set status
				this.setToggleClass( 'collapsing' );

				// Do the slideDown
				this.$elements.content.show().slideUp( this.dashboardSettings.slideToggleDuration, () => {
					// Set status
					this.setToggleClass( 'collapsed' );
				});
			}
		});
	}

	setToggleClass( status ){
		// First remove all the classes
		this.$elements.container.removeClass( Object.values( this.cssClasses.toggle ).join( ' ' ) );

		// Then add the correct class
		this.$elements.container.addClass( this.cssClasses.toggle[ status ] );
	}

	isCollapsed(){
		// Check if the container has the collapsed class
		return this.$elements.container.hasClass( this.cssClasses.toggle.collapsed );
	}

	createIndividualQuizzes(){
		// Find quizzes
		const $quizzes = this.$elements.container.find( `.${ this.cssClasses.individual }` );

		// Iterate each quiz
		$.each( $quizzes, ( index, $element ) => {
			// Create instance of Course
			new Quiz( $( $element ), this.dashboardSettings, this.courseData, this.lessonData );
		});
	}
}

class Quiz {
	constructor( $container, settings, courseData, lessonData = false ){
		// Save settings in the object's instance
		this.dashboardSettings = settings;
		// Save course data
		this.courseData        = courseData;
		// Save lesson data. This will be false
		// if the quiz is not inside a lesson
		this.lessonData        = lessonData;

		// Define the important CSS classes
		this.defineCssClasses();

		// Get important elements from the container
		this.getElements( $container );

		// Get quiz data
		this.getQuizData();

		// Add toggle functionality
		this.openStatistics();
	}

	getQuizData(){
		this.quizData = {
			course:      this.courseData,
			quizId:      this.$elements.container.data( 'quiz-id' ),
			isCompleted: !! parseInt( this.$elements.container.data( 'is-completed' ) ),
			passed:      !! parseInt( this.$elements.container.data( 'passed' ) ),
		}

		if ( isDefined( this.$elements.container.data( 'lesson-id' ) ) ){
			this.quizData.lesson = this.lessonData;
			delete this.quizData.lesson.course;
		}
	}

	defineCssClasses(){
		this.cssClasses = {
			statistics: {
				btn:     'ultp-dashboard-btn--statistics',
				content: '',
			}
		}
	}

	getElements( $container ){
		this.$elements = {
			container: $container,
			statistics: {
				btn:   $container.find( `.${ this.cssClasses.statistics.btn }` ),
				// content: $container.find( `.${ this.cssClasses.statistics.content }` )
			}
		}
	}

	openStatistics(){
		// Listen clicks on the statistics button
		this.$elements.statistics.btn.on( 'click', ( event ) => {
			// Prevent default
			event.preventDefault();

			// Open statistics
			console.log( this.quizData );
		});
	}
}

export default CourseDashboard;